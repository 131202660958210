<template>
  <div class="p-4 justify-center">
    <input v-bind:id="subscription.id" type="radio" class="hidden peer" name="subscription" :value="subscription" @input="onInput" required />
    <label v-bind:for="subscription.id"
      class="bg-surface-grey p-4 mb-3 flex justify-between items-center shadow rounded-lg border-2 border-light-grey dark:peer-checked:text-bee-green peer-checked:border-bee-green-600 peer-checked:bg-green-50">
      <div class="  flex flex-col justify-items-start">
        <span class="ml-2 flex text-charcoal-grey font-semibold font-poppins tracking-wide">{{$t('Name')}}: {{subscription.name}}</span>
        <span class="ml-2 pl-2 flex  text-graphite-grey font-poppins tracking-wide">{{$t('duration')}}: {{subscription.duration}}</span>
        <span class="ml-2 pl-2 flex  text-graphite-grey font-poppins tracking-wide">{{$t('monthly_interval')}}: {{subscription.interval}}</span>
        <span class="ml-2 pl-2 flex  text-graphite-grey font-poppins tracking-wide">{{$t('grace_period')}}: {{subscription.grace_period}}</span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    subscription: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onInput() {
      console.log('Selected Subscription')
      this.$emit('selected', this.subscription)
    }
  }
};
</script>

<style>

</style>