export default {
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "ConnectLedgerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your journals with ledgers"])},
  "SelectCashGap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your default Cash Gap ledger:"])},
  "Ledgers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledgers"])},
  "ArtikelLegers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel Ledgers"])},
  "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
  "AddDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add device"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "SerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial Number"])},
  "CoC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chamber of Commerce"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "RegisterMerchantTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register new merchant"])},
  "LabelFirstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
  "LabelLastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
  "LabelEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "LabelPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
  "Journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "monthly_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly interval"])},
  "grace_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grace period"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "SubscriptionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your humbee Abo"])},
  "ContactInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Information"])},
  "CompanyInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
  "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
  "CoC_Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoC"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NL"])},
  "LabelWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "LabelStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "LabelStreetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "LabelStreetNumberAdditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional"])},
  "LabelPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place/City"])},
  "PlaceholderPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
  "LabelZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZipCode"])},
  "PlaceholderZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1234AB"])},
  "LabelProverence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proverence"])},
  "PlaceholderProverence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proverence"])},
  "LabelCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "PlaceholderCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "LabelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "LabelUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "LabelPersonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "LabelIBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN no."])},
  "LabelAccountHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder"])},
  "LabelBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "PlaceholderIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NL02ABNA0123456789"])},
  "PlaceholderAccountHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr. John Doe"])},
  "PageTitleConnectPaymentLedger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your payment types to ledger"])},
  "CashRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Register"])},
  "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "PinPaymentMethode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Payment Methode"])},
  "CashPaymentMethode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Payment Methode"])},
  "CashRegistrerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Register ledger number"])},
  "PinPaymentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Ledger number"])},
  "CashPaymentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash ledger number"])}
}