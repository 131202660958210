export default {
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooi"])},
  "ConnectLedgerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppel je grootboek rekeningen"])},
  "SelectCashGap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer je kasverschil grootboek rekening"])},
  "Ledgers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grootboekrekeningen"])},
  "ArtikelLegers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel omzetgroepen"])},
  "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbind"])},
  "AddDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaat toevoegen"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "SerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienummer"])},
  "CoC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamer van Koophandel nummer"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
  "RegisterMerchantTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer een merchant account"])},
  "LabelFirstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "LabelLastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
  "LabelPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "LabelEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "LabelWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
  "Journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grootboek"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duur"])},
  "monthly_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandelijks interval"])},
  "grace_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grace period"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "SubscriptionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een abonnement"])},
  "ContactInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresgegevens"])},
  "CompanyInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsinformatie"])},
  "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfs naam"])},
  "CoC_Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KvK"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NL"])},
  "LabelStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
  "LabelStreetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
  "LabelStreetNumberAdditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toevoeging"])},
  "LabelPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorp/Stad"])},
  "PlaceholderPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats"])},
  "LabelZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "PlaceholderZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1234AB"])},
  "LabelProverence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincie"])},
  "PlaceholderProverence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincie"])},
  "LabelCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "LabelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "LabelUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
  "LabelPersonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aanhef."])},
  "LabelIBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN nummer"])},
  "LabelAccountHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeninghouder"])},
  "LabelBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedag"])},
  "PlaceholderCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "PlaceholderIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NL02ABNA0123456789"])},
  "PlaceholderAccountHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dhr. John Doe"])},
  "PageTitleConnectPaymentLedger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppel ledgers met Grootboekrekeningen"])},
  "CashRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa"])},
  "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteer"])},
  "PinPaymentMethode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Betaalwijze"])},
  "CashPaymentMethode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contant Betaalwijze"])},
  "CashRegistrerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassa dagboek nummer"])},
  "PinPaymentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grootboek rekening nummer"])},
  "CashPaymentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grootboek rekening nummer"])}
}