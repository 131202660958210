export const APISettings = {
    baseURL: parse(process.env.VUE_APP_API_BASE_URL, 'https://api.staging.octoblue.nl'),
}

export const KeycloakSettings = {
        url: parse(process.env.VUE_APP_KEYCLOAK_URL, 'https://keycloak.actablue.com/auth'),
        realm: parse(process.env.VUE_APP_KEYCLOAK_REALM, 'actablue'),
        clientId: parse(process.env.VUE_APP_KEYCLOAK_CLIENT_ID, 'humbee-login'),
        onLoad: 'login-required'
}

function parse(value, fallback) {
    if (typeof value === 'undefined') {
        return fallback
    }
    switch (typeof fallback) {
        case 'boolean':
            return !!JSON.parse(value)
        case 'number':
            return JSON.parse(value)
        default:
            return value
    }
}