import { APISettings } from '@/config';
import axios from 'axios';

export default {
    config: {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=utf-8',
        }
    },

    async createRegistration(registration, access_token) {
        const response = await fetch(APISettings.baseURL + '/registrations/registration', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                "Authorization": "Bearer " + access_token
            },
            body: JSON.stringify(registration)
        });
        console.log(response);
        if (response.status != 200) {
            throw response;
        } else {
            return response.json();
        }
    },

    async createMerchant(user, app, access_token){

        const response = await fetch(APISettings.baseURL + '/registrations/registration/merchant/' + app, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                "Authorization": "Bearer " + access_token
            },
            body: JSON.stringify(user)
        });
        console.log(response);
        if (response.status != 200) {
            throw response;
        } else {
            return response.json();
        }
    },

    async getRegistrationByAdministrationAndLocation(administration, location, access_token){
        this.config.headers['Authorization'] = 'Bearer '+access_token 
        return axios
            .get(APISettings.baseURL + '/registrations/registration?administration_id='+ administration +'&location_id=' + location, this.config)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.error(error)
                throw error
            });
    },

}