<template>
  <div class="w-full grid items-center justify-center ">
    <h2 class="pb-4 text-3xl text-charcoal-grey font-poppins font-bold underline">{{ $t('RegisterMerchantTitle') }}</h2>
    <div class="max-w-lg  bg-white shadow-md rounded px-8 pt-6 pb-8">
      <div class="max-w-lg flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block text-charcoal-grey text-xl font-poppins font-bold mb-2" for="firstname">
            {{ $t('LabelFirstname') }}
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
            id="firstname" type="text" placeholder="John" :value="firstname" @input="updateAccountInformation">
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block text-charcoal-grey text-xl font-bold font-poppins mb-2" for="lastname">
            {{ $t('LabelLastname') }}
        
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
            id="lastname" type="text" placeholder="Doe" :value="lastname" @input="updateAccountInformation">
        </div>
      </div>
      <div class="w-full flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3 mb-6 md:mb-0">
          <label class="block text-charcoal-grey text-xl font-bold font-poppins mb-2" for="username">
            {{ $t('LabelEmail') }}
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
            id="username" type="email" placeholder="Email" :value="username" @input="updateAccountInformation">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {

  computed: mapState({
    username: state => state.account.username,
    password: state => state.account.password,
    firstname: state => state.account.firstname,
    lastname: state => state.account.lastname,
  })
  ,
  methods: {
    updateAccountInformation(e) {
      this.$store.commit('updateAccountInformation', { 'id': e.target.id, 'value': e.target.value })
    },
  }

}
</script>

<style>

</style>