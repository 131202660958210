<template>
    <div class="w-full grid items-center justify-center " >
    <h2 class="w-full pb-4 text-3xl text-charcoal-grey font-bold font-poppins underline">{{$t('ContactInformationTitle')}}</h2>
    <div class="w-full bg-white shadow-md rounded px-8 pt-6 pb-8">
    <div class="w-full flex -mx-3 mb-6">
      <div class="md:w-4/6 px-3 mb-6 md:mb-0">
        <label class="block text-charcoal-grey font-bold font-poppins mb-2" for="street">
          {{$t('LabelStreet')}}
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
          id="street" type="text" v-bind:placeholder="$t('LabelStreet')" :value="street" @input="updateInput">
      </div>
      <div class="md:w-1/6 px-3 mb-6 md:mb-0">
        <label class="block text-charcoal-grey font-bold font-poppins mb-2" for="street_number">
          {{$t('LabelStreetNumber')}}
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
          id="street_number" type="number" placeholder="1" :value="street_number" @input="updateInput">
      </div>
      <div class="md:w-1/6 px-3 mb-6 md:mb-0">
        <label class="block text-charcoal-grey font-bold font-poppins mb-2" for="street_number_additional">
          {{$t('LabelStreetNumberAdditional')}}
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
          id="street_number_additional" type="text" placeholder="a" :value="street_number_additional" @input="updateInput">
      </div>
    </div>
    <div class="w-full flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-3/4 px-3 mb-6 md:mb-0">
        <label class="block text-charcoal-grey font-bold font-poppins mb-2" for="place">
          {{$t('LabelPlace')}}
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
          id="place" type="text" v-bind:placeholder="$t('PlaceholderPlace')" :value="place" @input="updateInput">
      </div>
      <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
        <label class="block text-charcoal-grey font-bold font-poppins mb-2" for="zip_code">
          {{$t('LabelZipCode')}}
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
          id="zip_code" type="text" v-bind:placeholder="$t('PlaceholderZipCode')" :value="zip_code" @input="updateInput">
      </div>
    </div>
    <div class="w-full flex flex-wrap -mx-3 mb-6">
      <div class="md:w-1/2 px-3 mb-6 md:mb-0">
        <label class="block text-charcoal-grey  font-bold font-poppins mb-2" for="proverence">
          {{$t('LabelProverence')}}
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
          id="proverence" type="text" v-bind:placeholder="$t('PlaceholderProverence')" :value="proverence" @input="updateInput">
      </div>
      <div class="md:w-1/2 px-3 mb-6 md:mb-0">
        <label class="block text-charcoal-grey  font-bold font-poppins mb-2" for="country">
          {{$t('LabelCountry')}}
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
          id="country" type="text" v-bind:placeholder="$t('PlaceholderCountry')" :value="country" @input="updateInput">
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: mapState({
    street: state => state.address.street,
    street_number: state => state.address.street_number,
    street_number_additional: state => state.address.street_number_additional,
    zip_code: state => state.address.zip_code,
    place: state => state.address.place,
    proverence: state => state.address.proverence,
    country: state => state.address.country
  }),
  methods: {
    updateInput(e){
      this.$store.commit('updateAddressInformation', {'id': e.target.id, 'value': e.target.value})
    },
  }

}
</script>

<style>

</style>