import { APISettings } from '@/config';

export default {
    props: ['keycloak'],
    getByType(type) {  
        return fetch( APISettings.baseURL + '/registrations/subscription/list?type=' + type, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8'
            },
        } )
        .then( function( response ){
            console.log(response)
            if( response.status != 200 ){
                throw response.status;
            }else{
                return response.json();
            }
        });
    },


}