<template>
  <div class="w-full grid items-center justify-center ">
    <h2 class="w-full pb-4 text-3xl  text-charcoal-grey font-poppins font-bold underline">{{$t('CompanyInfoTitle')}}</h2>
    <div class="w-full bg-white shadow-md rounded px-8 pt-6 pb-8">
      <div class="w-full flex -mx-3 mb-6">
        <div class="w-full px-3 mb-6 md:mb-0">
          <label class="block text-charcoal-grey font-poppins font-bold mb-2" for="name">
            {{$t('Name')}}
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
            id="name" type="text" v-bind:placeholder="$t('CompanyName')" :value="name" @input="updateInput">
        </div>
      </div>
      <div class="w-full flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-3/4 px-3 mb-6 md:mb-0">
          <label class="block text-charcoal-grey font-bold font-poppins mb-2" for="coc">
            {{$t('CoC')}}
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
            id="coc" type="text" v-bind:placeholder="$t('CoC_Placeholder')" :value="coc" @input="updateInput">
        </div>
        <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
          <label class="block text-charcoal-grey font-bold font-poppins mb-2" for="country_code">
            {{$t('Country')}}
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
            id="country_code" type="text" v-bind:placeholder="$t('country_code')" :value="country_code" @input="updateInput">
        </div>
      </div>
      <div class="w-full flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3 mb-6 md:mb-0">
          <label class="block text-charcoal-grey  font-bold  font-poppins mb-2" for="website_url">
            {{$t('LabelWebsite')}}
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal-grey font-poppins leading-tight focus:outline-none focus:shadow-outline"
            id="website_url" type="text" placeholder="https://.." :value="website_url" @input="updateInput">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: mapState({
    name: state => state.company.name,
    coc: state => state.company.coc,
    country_code: state => state.company.country_code,
    website_url: state => state.company.website_url
  }),
  methods: {
    updateInput(e) {
      this.$store.commit('updateCompanyInformation', { 'id': e.target.id, 'value': e.target.value })
    },
  }

}
</script>

<style>

</style>