<template>
  <div class="h-screen w-screen">
    <div class="h-fit w-screen grid justify-center content-center p-4"><img src="@/assets/logo.png" /></div>
    <div class="grid  justify-items-center">
      <BoardingForm class="max-w-4xl" />
    </div>
  </div>
</template>

<script>
import BoardingForm from './components/BoardingForm.vue'
import 'form-wizard-vue3/dist/form-wizard-vue3.css'
export default {
  name: 'App',
  inject: ['keycloak'],
  components: {
    BoardingForm,
  }
}
</script>

<style>
#app {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: theme('colors.white') !important;
  color: theme('colors.graphite-grey');
}

.form-wizard-vue .fw-step-active {
  background: theme('colors.acta-blue.DEFAULT') !important;
  color: #fff !important
}

.form-wizard-vue .fw-step-checked {
  border: 2px solid theme('colors.acta-blue.DEFAULT');
}

.form-wizard-vue .fw-body-list {
  overflow: none!important;
}

.form-wizard-vue .fw-body {
  overflow: none!important;
  padding-top: 10px;
  padding: 0
}

.form-wizard-vue .fw-body-content{
  padding: 0;
}

.fw-vertical .fw-body-content {
  padding-top: 10px;
}

.form-wizard-vue .fw-body-list .fw-list-progress-active {
  background: linear-gradient(90deg, theme('colors.acta-orange.200') -3.12%, theme('colors.acta-orange.DEFAULT') 105.52%);
}

.form-wizard-vue .fw-btn {
  background: theme('colors.acta-blue.DEFAULT');
}

.fw-vertical .fw-body-list li .fw-list-progress-active {
  background: linear-gradient(90deg, theme('colors.acta-orange.200') -3.12%, theme('colors.acta-orange.DEFAULT') 105.52%);
}
</style>
